//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";

export default BaseCmsBlock.extend({
  name: "AboutBlock",
  data() {
    return {
      isShowFull: false,
    };
  },
  components: {},
});
